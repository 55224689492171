<template>
  <div class="Box">
    <div v-if="!isApp" class="tabbar" :class="navBarFixed ? 'navBarFixed': ''">
      <img @click="goback" src="../../../../static/common/logo.png" alt="">
    </div>
    <img class="banner" src="/subject/worldCup/wap/banner-wap.jpg" alt="">
    <div class="BIG_BOX">
      <div class="choice">
        <img class="choiceTit" src="/subject/worldCup/eight.png" alt="">
        <img @click="()=>{activityDetailsDialog = true;}" class="details" src="/subject/worldCup/details.png" alt="">
        <div class="choiceContent">
          <div class="choiceTop">
            <div v-for="item in data" :key="item.id">
              <!-- <p>{{item.title}}</p> -->
              <ul>
                <li @click="choice(info,item.title)" v-for="info in item.info" :key="info.index" :class="[info.control ? 'userChoiceLi' : '' , selectCountry.length>7 ? 'disabledLi' : '']">
                  <img class="flag" :src="info.flag" alt="">
                  <span>{{info.country}}</span>
                  <img v-if="info.control" class="select" src="/subject/worldCup/select.png" alt="">
                </li>
              </ul>
            </div>
          </div>
          <!-- <img class="lrhd" src="/subject/worldCup/wap/lrhd.png" alt=""> -->
          <div class="choiceBottom">
            <div class="userStatus" v-if="userStatus === 0">
              <div class="bottomForm">
                <input type="text" v-model="form.mobile" @blur="craigBlur" placeholder="请输入您的手机号">
                <div class="codeBox">
                  <input type="text" v-model="form.code" placeholder="输入验证码">
                  <span v-if="verControl" @click="verCode(form.mobile)">获取验证码</span>
                  <span v-else>重新获取({{verSecond}})</span>
                </div>
                <img @click="submit(form)" src="/subject/worldCup/submit.png" alt="">
              </div>
              <p class="newTips">未注册用户，提交竞猜结果后，本平台会自动为您完成注册开户，以方便您查看投票结果及奖励发放进程。</p>
              <div class="bottomLogin">
                <span>已有账号</span>
                <img @click="loginMethod" src="/subject/worldCup/login.png" alt="">
              </div>
            </div>
            <div class="userStatus1" v-if="userStatus === 1">
              <input :value="`您的交易账号：`+userAccount" disabled type="text" placeholder="系统代入 您的交易账号">
              <img @click="vote()" src="/subject/worldCup/submit.png" alt="">
            </div>
            <div class="userStatus2" v-if="userStatus === 2">
              <p>亲爱的用户：<span>{{userAccount}}</span> 您选择的队伍是<br><span>{{userResult}}</span></p>
              <p>为了活动公平公正，目前您的竞猜结果 <span>已锁定，</span>请2022年12月19日登录该页面，查看中奖结果。</p>
            </div>
          </div>
          <p class="endtime">竞猜结果揭晓时间 &nbsp; 官方公布冠军得主后的次日</p>
        </div>
      </div>
      <img class="process" src="/subject/worldCup/wap/process.png" alt="">
      <div class="register">
        <img @click="register" class="registerImg1" src="/subject/worldCup/registerButton.png" alt="">
        <img @click="live800" class="registerImg2" src="/subject/worldCup/live800Button.png" alt="">
      </div>
      <div class="LuckyBox">
        <img class="luckyTit" src="/subject/worldCup/luckyTit.png" alt="">
        <div class="draw">
          <p class="drawTit">
            <!-- <span>恭喜*****70用户2分钟前抽中</span>
            <span>88元微信红包</span> -->
            <span>红狮助力，最高1000美元等你拿！</span>
          </p>
          <LuckyWheel
            class="LuckyWheel"
            ref="myLucky"
            width="300px"
            height="300px"
            :prizes="prizes"
            :blocks="blocks"
            :buttons="buttons"
            @start="startCallback"
            @end="endCallback"
          />
          <img class="zpBottom" src="/subject/worldCup/wap/zpBottom.png" alt="">
          <div class="showPrize">
            <div class="spLeft">
              <p>您的抽奖次数:</p>
              <p>您抽中的大奖:</p>
            </div>
            <div v-if="userStatus === 0" class="spRight">
              <img @click="loginMethod" src="/subject/worldCup/loginShowButton.png" alt="">
            </div>
            <div v-if="userStatus === 3" class="spRight">
              <p>即将揭晓，敬请期待...</p>
            </div>
            <div v-if="userStatus === 1 || userStatus === 2" class="spRight2">
              <p>{{surplusnum}}次</p>
              <p class="scrollP">
                <span v-for="item in this.turntablelogs" :key="item.id">{{item.value}}</span>
              </p>
            </div>
          </div>
          <div class="handleBox">
            <img src="/subject/worldCup/handle.png" alt="">
            <span>请上下滑动查看更多奖品</span>
          </div>
        </div>
      </div>
      <div class="craig">
        <img class="craigTit" src="/subject/worldCup/craigTit.png" alt="">
        <img class="craigBanner" src="/subject/worldCup/wap/craigBanner.png" alt="">
      </div>
      <div class="footer">
        <img class="footerTxt" src="/subject/worldCup/wap/footerTxt.png" alt="">
        <ul>
          <li>
            <img src="/subject/worldCup/ios.png" alt="">
            <img src="/subject/worldCup/iosCode.png" alt="">
          </li>
          <li>
            <img src="/subject/worldCup/android.png" alt="">
            <img src="/subject/worldCup/androidcode.png" alt="">
          </li>
        </ul>
      </div>
    </div>
    <div v-if="controlDialog || activityDetailsDialog" class="diaShadow"></div>
    <div v-if="controlDialog" class="dialog">
      <h3 v-if="messageAll.messageTitle === 0">很遗憾</h3>
      <h3 v-if="messageAll.messageTitle === 1">恭喜！你获得</h3>
      <h3 class="double" v-if="messageAll.messageTitle === 2">
        <span>锦鲤附体！</span>
        <span>恭喜抽中最高大奖！</span>
      </h3>
      <p :class="(messageAll.messageTitle === 1 ? 'messagePyellow':(messageAll.messageTitle === 2 ? 'messagePred' : 'messagePBlack'))">{{messageAll.messagePPP}}</p>
      <p style="margin-top:5px" v-if="(messageAll.messageTitle === 1 || messageAll.messageTitle === 2)">赠金点</p>
      <button @click="closerDialog" class="messageButton">{{messageAll.messageButton}}</button>
      <img :src="(messageAll.messageTitle === 0 ? '/subject/worldCup/1.png' : (messageAll.messageTitle === 1 ? '/subject/worldCup/2.png' : '/subject/worldCup/3.png'))" alt="">
    </div>
    <div v-if="controlImgCodeDialog" class="codeDialog">
      <img :src="imgcodelocal" alt="">
      <p>
        <span>图形验证码</span>
        <input type="text" v-model="form.codeNumber">
      </p>
      <div>
        <button @click="()=>{this.controlImgCodeDialog = false}">取消</button>
        <button @click="confirm(form.codeNumber)">确定</button>
      </div>
    </div>
    <div v-if="activityDetailsDialog" class="activityDetailsDialog">
      <img @click="()=>{activityDetailsDialog = false}" src="/subject/worldCup/closer2.png" alt="">
      <div>
        <h3>· 活动时间：</h3>
        <p>2022年11月20日00:00至12月23日23:59；</p>
        <h3>· 活动对象：</h3>
        <p>本公司所有新老客户</p>
        <h3>· 活动规则：</h3>
        <p>
          活动期间内，客户通过活动页面勾选自己看好的足球队，确认后提交竞猜结果，猜对可获得转盘抽奖机会；
        </p>
        <p>
          2、官方出炉8强、4强及冠军名单后，我平台会在次日更新数据，中奖客户即可进行抽奖；
        </p>
        <p> &nbsp; ①猜8强：2022年11月20日00:00 至 2022年12月3日12:00;</p>
        <p> &nbsp; ②猜4强 ：2022年12月3日23:00 至 2022年12月8日00:00;</p>
        <p> &nbsp; ③猜冠军：2022年12月9日23:00 至 2022年12月18日22:00;</p>
        <p>
          2、每组竞猜结果公布时间为：竞猜结束后的下一个工作日10:00
        </p>
        <p>
          3、每猜中1组获胜球队，即可获得一次转盘抽奖机会，每位客户最多可获得13次抽奖机会；
        </p>
        <p>
          4、客户完成抽奖后，可在活动页面查看自己的抽奖情况；
        </p>
        <p>
          5、抽奖仅在活动有效期内有效，逾期作废；
        </p>
        <h3>· 奖励领取规则</h3>
        <p>
          1、获奖客户可下载<span>红狮启富App</span>，登陆后前往<span>"我的"-"赠金奖励"</span>中查看奖励明细；<br>
          2、<span>美元现金：</span>客户可通过手机App，登陆后前往"我的"-"我的资产"中查看，美元现金可直接用于交易；<br>
          3、<span>赠金点：</span>客户可通过手机App，登陆后前往"我的"-"赠金奖励"中查看，赠金点交易后可兑换，笔笔兑换，随时提现；<br>
          4、<span>微信红包及话费充值：</span>在活动有效期内，通过手机App联系平台客服进行领取，逾期作废；
        </p>
        <h3>· 注意事项</h3>
        <p>
          1. 客户参与活动需在本公司开立真实交易账户,参与该活动的新用户，为保证资金安全，在初始L1层级，账户强平比例上调至50%，升级后自动恢复为20%；<br>
          2. 客户提交竞猜结果后，在下轮竞猜开始前，将不可再修改选项；<br>
          3. 客户抽奖后所获奖品，将自动发放至客户账户，客户可在活动页面或个人中心查看；<br>
          4. 凡推广商及其名下客户不参与本活动；<br>
          5. 本公司保留随时修订、暂停、终止本活动及任何相关规则条款之权利及其解释权。
        </p>
      </div>
    </div>
    <contactusVue v-if="!isApp"></contactusVue>
  </div>
</template>

<script>
import {activityVote , verifyImgCode ,quickRegister, VoteDetails ,searchId , luckDraw ,searchUserNum ,searchUserHistory ,maidianOpenreal} from '../../../../api/info';
import {getGuid} from '../../../../utils/guid';
import ContactusVue from "../../Contactus.vue";
export default {
  name:'worldCupWap',
  components:{
    ContactusVue,
  },
  data(){
    return{
      // 转盘插件变量
      blocks: [{
        padding: '20px',
        imgs:[{
          src:'/subject/worldCup/wheel/background.png',
          width:'300px',
          height:'300px',
          top:'0px',
        }]
      }],
      prizes: [
      ],
      buttons: [{
        radius: '25%',
        pointer: true,
        imgs:[{src:`/subject/worldCup/wheel/zpButton.png`,top:'-60px',width:'100px',height:'110px'}]
      }],
      // 国家信息
      data:[
        {
          id:0,
          title:'A组',
          info:[
            {flag:'/subject/worldCup/countryFlag/24.png',country:'克罗地亚',index:23,control:false},
            {flag:'/subject/worldCup/countryFlag/25.png',country:'巴西',index:24,control:false},
            {flag:'/subject/worldCup/countryFlag/4.png',country:'荷兰',index:3,control:false},
            {flag:'/subject/worldCup/countryFlag/9.png',country:'阿根廷',index:8,control:false},
          ]
        },
        {
          id:1,
          title:'B组',
          info:[
            {flag:'/subject/worldCup/countryFlag/13.png',country:'法国',index:12,control:false},
            {flag:'/subject/worldCup/countryFlag/5.png',country:'英格兰',index:4,control:false},
            {flag:'/subject/worldCup/countryFlag/23.png',country:'摩洛哥',index:22,control:false},
            {flag:'/subject/worldCup/countryFlag/29.png',country:'葡萄牙',index:28,control:false},
          ]
        },
      ],
      // 储存选择的国家
      selectCountry:[],
      // 控制选中样式
      IsControl:false,
      // 控制弹出dialog
      controlDialog:false,
      // 用户form表单信息
      form:{
        mobile:'',
        code:'',
        codeNumber:'',
      },
      // 控制用户状态
      userStatus:0,
      // 验证码读秒
      verSecond: 60,
      // 控制显示验证码或读秒
      verControl:true,
      refreshData:null,
      // 控制图片验证码弹窗
      controlImgCodeDialog:false,
      // 提示内容
      messageAll:{
        messageTitle:0,
        messagePPP:'',
        messageButton:'',
      },
      // url参数
      queryCup:'',
      // 图片验证码地址
      imgcodelocal:'',
      guid:'',
      scroll: '',
      navBarFixed: false,
      // 用户投票结果
      userResult:'',
      // 用户账号
      userAccount:'',
      activityDetailsDialog: false,
      screenWidth:null,
      // 存储后端返回的索引
      cjIndex:null,
      // 用户抽奖次数
      surplusnum:'',
      // 用户历史奖励
      turntablelogs:[],
      // 判断是否在app打开
      isApp: false,
    }
  },
  created(){
    // 传给App.vue,不展示tabbar
    this.$emit('controlShow',true);
    sessionStorage.setItem('worldCup',true);
    // 判断用户是否是自己登陆
    if(!sessionStorage.getItem('account')){
      if(!this.$route.query.account){
        this.userStatus = 0;
      }else{
        sessionStorage.setItem('account',this.$route.query.account);
        this.userAccount = this.$route.query.account;
        this.initVote(sessionStorage.getItem('account'));
        this.initUserNum(sessionStorage.getItem('account'));
        this.initUserHistory(sessionStorage.getItem('account'));
      }
    }else{
      this.initVote(sessionStorage.getItem('account'));
      this.initUserNum(sessionStorage.getItem('account'));
      this.initUserHistory(sessionStorage.getItem('account'));
    };
    // 判断是从A前还是A后跳转过来
    if(!sessionStorage.getItem('craig')){
      if(!this.$route.query.craig){
        sessionStorage.setItem('craig',window.location.origin);
      }else{
        sessionStorage.setItem('craig',this.$route.query.craig);
      }
    };
    if(this.$route.query.isApp){
      this.isApp = true;
    };
    //查询转盘
    searchId(27).then(res=>{
      if(res.status === 0){
        res.awardlist.forEach((item,index)=>{
          const prize = {
            background:index%2 === 1 ? '#fdf0e3' : '#fffbf5',
            imgs:[{
              src:item.awardicon,
              top:'20px',
              width:'40px',
              height:'45px',
            }]
          }
          this.prizes.push(prize);
        })
      }else{
        this.$message({
          message: '获取转盘信息失败!',
          type: 'warning'
        });
      }
    })
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth
      })();
    };
  },
  watch: {
    screenWidth: function (n) {
      if (n > 500) {
        this.$router.push({
          name:'worldCupWeb',
        })
      }
    }
  },
  methods:{
    // 初始化，查看投票详情
    initVote(attr){
      let initData={
        activityId:37,
        Account:Number(attr),
      }
      VoteDetails(initData).then(res=>{
        if(res.IsSuccess == true){
          if(!res.Data){
            this.userAccount = sessionStorage.getItem('account') || '';
            this.userStatus = 1;
          }else{
            this.userResult = res.Data.voteResult;
            this.userAccount = res.Data.account;
            this.userName = res.Data.name;
            this.userStatus = 2;
          }
        }
      })
    },
    // 控制顶部的定位
    handleScroll() {
      this.scroll = document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
      if(this.scroll > 90 ){
        this.navBarFixed = true;
      }else{
        this.navBarFixed = false;
      }
    },
    // 转盘插件对应的逻辑
    // 点击抽奖按钮会触发star回调
    startCallback () {
      // 模拟调用接口异步抽奖
      if(!sessionStorage.getItem('account')){
        this.$message({
          message: '请先登录账号!',
          type: 'warning'
        });
      }else{
        if(this.surplusnum>0){
          let initData={
            Account:Number(sessionStorage.getItem('account')),
            TurntableId:27,
          };
          luckDraw(initData).then(res=>{
            if(res.status === 0 ){
              if(res.turntableLog){
                // 调用抽奖组件的play方法开始游戏
                this.$refs.myLucky.play();
                if(res.turntableLog.awardname.indexOf('赠')!==-1){
                  this.messageAll.messagePPP = res.turntableLog.awardname.split('赠')[0];
                }else{
                  this.messageAll.messagePPP = res.turntableLog.awardname;
                }
                switch(res.turntableLog.awardname){
                  case '未抽中':
                    this.cjIndex = 9 ;
                    this.$refs.myLucky.stop(this.cjIndex);
                    this.messageAll.messageButton = '关闭';
                    this.messageAll.messageTitle = 0;
                    break;
                  case '10元话费':
                    this.cjIndex = 8 ;
                    this.$refs.myLucky.stop(this.cjIndex);
                    this.messageAll.messageButton = '开心收下';
                    this.messageAll.messageTitle = 1;
                    break;
                  case '10美元赠金点':
                    this.cjIndex = 4 ;
                    this.$refs.myLucky.stop(this.cjIndex);
                    this.messageAll.messageButton = '开心收下';
                    this.messageAll.messageTitle = 1;
                    break;
                  case '10美元赠金点':
                    this.cjIndex = 5 ;
                    this.$refs.myLucky.stop(this.cjIndex);
                    this.messageAll.messageButton = '开心收下';
                    this.messageAll.messageTitle = 1;
                    break;
                  case '66元微信红包':
                    this.cjIndex = 7 ;
                    this.$refs.myLucky.stop(this.cjIndex);
                    this.messageAll.messageButton = '开心收下';
                    this.messageAll.messageTitle = 1;
                    break;
                  case '88元微信红包':
                    this.cjIndex = 6 ;
                    this.$refs.myLucky.stop(this.cjIndex);
                    this.messageAll.messageButton = '开心收下';
                    this.messageAll.messageTitle = 1;
                    break;
                  case '100美元赠金点':
                    this.cjIndex = 3 ;
                    this.$refs.myLucky.stop(this.cjIndex);
                    this.messageAll.messageButton = '开心收下';
                    this. messageAll.messageTitle = 1;
                    break;
                  case '200美元赠金点':
                    this.cjIndex = 2 ;
                    this.$refs.myLucky.stop(this.cjIndex);
                    this.messageAll.messageButton = '开心收下';
                    this.messageAll.messageTitle = 1;
                    break;
                  case '500美元赠金点':
                    this.cjIndex = 1 ;
                    this.$refs.myLucky.stop(this.cjIndex);
                    this.messageAll.messageButton = '开心收下';
                    this.messageAll.messageTitle = 1;
                    break;
                  case '1000美元赠金点':
                    this.cjIndex = 0 ;
                    this.$refs.myLucky.stop(this.cjIndex);
                    this.messageAll.messageButton = '收下好运';
                    this.messageAll.messageTitle = 2;
                    break;
                }
                // setTimeout(()=>{
                //   // 调用stop停止旋转并传递中奖索引
                //   this.$refs.myLucky.stop(this.cjIndex);
                // },3000);
              }else{
                this.$message({
                  message: res.msg,
                  type: 'warning'
                });
              }
            }else{
              this.$message({
                message: '抽取奖励失败！',
                type: 'warning'
              });
            }
          })
        }else{
          this.$message({
            message: '您无可用的抽奖次数！',
            type: 'warning'
          });
        }
      }
    },
    // 抽奖结束会触发end回调
    endCallback (prize) {
      console.log(prize);
      this.controlDialog = true;
      this.initUserNum(sessionStorage.getItem('account'));
    },
    //点击选择国家（4强）
    choice(info,title){
      // 判断选择的国家之前是否已选择
      let idIndex = this.selectCountry.indexOf(info.country);
      if(idIndex >= 0){
        // 已选择，再次点击删除；
        this.selectCountry.splice(idIndex, 1);
        info.control = false;
      }else{
        // 判断总选择数是否超过4个
        if(this.selectCountry.length>0){
          // 超过8个，结束函数
          this.$message({
            message: '请最多选择一支球队!',
            type: 'warning'
          });
          return;
        }else{
          // 没有超过8个继续push
          this.selectCountry.push(info.country);
          info.control = true;
        }
      }
    },
    // 判断两个数组有几个相同元素
    compare(arr1, arr2) {
      return arr1.filter((v) => {
        return arr2.indexOf(v) !== -1;
      });
    },
    // 判断两个数组有几个相同元素
    sameNumber(arr1,arr2){
      let A = new Set(arr1);
      let B = new Set(arr2);
      let result = new Set([...A].filter(x=>B.has(x)));
      return result
    },
    // 点击获取验证码
    verCode(attr){
      // crm数据埋点
      maidianOpenreal({ eventname: 'rr3', eventdescribe: '发送验证码' ,eventType:'click'});
      if(this.phone(attr)){
        this.controlImgCodeDialog = true;
        this.countdowm();
        this.guid = getGuid();
        console.log(this.guid);
        this.imgcodelocal = window.location.origin + '/api/proxy/v4/U104?key=' + this.guid;
      }else{
        this.$message({
          message: '请输入正确手机号!',
          type: 'warning'
        });
      }
    },
    // 提交验证码
    confirm(attr){
      if(attr){
        let data = {
          Mobile: this.form.mobile,
          Token: this.guid,
          ImgCode:Number(attr),
        }
        console.log(this.guid);
        // 获取图片验证码接口
        verifyImgCode(data).then(res=>{
          if(res.ok == 1){
            this.$message({
              message: res.msg,
              type: 'warning'
            });
            // this.form.code = res.data;
            this.verControl = !this.verControl;
            this.controlImgCodeDialog = false;
          }else{
            this.$message({
              message: res.msg,
              type: 'warning',
            });
            this.controlImgCodeDialog = false;
          }
        })
      }else{
        this.$message({
          message: '请输入图片验证码结果!',
          type: 'warning'
        });
      }
    },
    // 提交手机号验证码
    submit(form){
      // crm数据埋点
      maidianOpenreal({ eventname: 'rr4', eventdescribe: '提交资料数' ,eventType:'click'});
      if(form.mobile && form.code){
        if(this.selectCountry.length !==1){
          this.$message({
            message: '请选择一支球队!',
            type: 'warning'
          });
        }else{
          let data = {
            IsQuickRregister:true,
            Mobile:this.form.mobile,
            Code:this.form.code,
            Token:this.guid,
            LoginType:1,
            AuthMode:1,
          };
          quickRegister(data).then(res=>{
            console.log(res);
            if(res.ok == 1){
              this.$message({
                message: res.msg,
                type: 'warning',
              });
              sessionStorage.setItem('account',res.data.Account);
              this.vote(res.data.Account);
              // crm数据埋点
              maidianOpenreal({ eventname: 'rr5', eventdescribe: '成功开户数' ,eventType:'click'});
            }else{
              this.$message({
                message: res.msg,
                type: 'warning'
              });
            }
          })
        }
      }else{
        this.$message({
          message: '请输入手机号和验证码!',
          type: 'warning'
        });
      }
    },
    // 投票方法
    vote(attr){
      var voteData={
        activityId:37,
        account:Number(sessionStorage.getItem('account')),
        voteResult:String(this.selectCountry),
        name:this.userName,
      } 
      if(!attr){}else{
        voteData.account = Number(attr);
      }
      if(this.selectCountry.length !==1){
        this.$message({
          message: '请选择一支球队!',
          type: 'warning'
        });
      }else{
        console.log('投票!');
        activityVote(voteData).then(res=>{
          if(res.IsSuccess){
            this.$message({
              message: res.Data,
              type: 'warning'
            });
            this.initVote(voteData.account);
          }else{
            this.$message({
              message: res.Data,
              type: 'warning'
            });
          }
          this.Reset();
        });
      }
    },  
    // 投票完成后之前的投票记录置空
    Reset(){
      this.data.forEach(element => {
        element.info.forEach(e=>{
          e.control = false;
        })
      });
      this.selectCountry = [];
      this.form.mobile = '';
      this.form.code = '';
    },
    // 正则判断电话号码格式是否正确
    phone(TEL) {
      let strTemp = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
      if (strTemp.test(TEL)) {
        return true;
      }
      return false;
    },
    // 读秒
    countdowm() {
      this.refreshData = window.setInterval(() => {
        this.verSecond--;
        if (this.verSecond < 0) {
          //当倒计时小于0时清除定时器
          window.clearInterval(this.refreshData); //清除定时器
          this.verSecond = 60;
          this.verControl = true;
        }
      }, 1000);
    },
    // 关闭弹窗
    closerDialog(){
      this.controlDialog = false;
      this.messageAll.messagePPP = '';
      this.messageAll.messageButton = '';
      this.initUserHistory(sessionStorage.getItem('account'));
    },
    // 登录
    loginMethod(){
      window.location.href=  window.location.origin + '/uc/login?'+'worldcup=worldcup';
      // window.location.href= 'http://192.168.0.102:8100/uc/login?'+'worldcup=worldcup';
    },
    // 注册
    register(){
      window.location.href=  window.location.origin + '/uc/signUp/real?'+'worldcup=worldcup';
      // crm数据埋点
      maidianOpenreal({ eventname: 'rr1', eventdescribe: '点击开户数' ,eventType:'click'});
    },
    // 返回上级页面
    goback(){
      window.location.href = sessionStorage.getItem('craig');
    },
    // 客服链接
    live800(){
      window.open('https://chat32.live800.com/live800/chatClient/chatbox.jsp?companyID=215184&configID=35519&jid=5377035377');
    },
    craigBlur(){
      // crm数据埋点
      maidianOpenreal({ eventname: 'rr2', eventdescribe: '开户填写手机号' ,eventType:'input'});
    },
    /*************************************抽奖逻辑 */
    // 查询抽奖次数
    initUserNum(attr){
      let initData = {
        TurntablenumStatus:{
          accountid:attr,
          turntableid:27,
          platform:1,
        }
      }
      searchUserNum(initData).then(res=>{
        if(res.status === 0){
          this.surplusnum = res.surplusnum;
        }
      })
    },
    // 查询历史抽奖结果
    initUserHistory(attr){
      let initData = {
        TurntablenumStatus:{
          accountid:attr,
          turntableid:27,
          platform:1,
        }
      }
      searchUserHistory(initData).then(res=>{
        if(res.status === 0){
          let craigArr = [];
          res.turntablelogs.map((item)=>{
            craigArr.push({
              value:item.awardname,
              id:item.id,
            })
          });
          let r = craigArr.filter(item=>{
            return item.value != '未抽中'
          })
          this.turntablelogs = r;
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .Box{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    .tabbar{
      width: 100%;
      background-color: #ffffff;
      img{
        margin: 10px 30px;
        width: 130px;
      }
    }
    .navBarFixed{
      position: fixed;
      top: 0;
      z-index: 999;
    }
    .banner{
      width: 100%;
    }
    .BIG_BOX{
      width: 100%;
      height: auto;
      background: url('/subject/worldCup/wap/background.png') no-repeat;
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      .choice{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .choiceTit{
          width: 70%;
        }
        .details{
          width: 88px;
          margin: 10px auto 10px;
        }
        .choiceContent{
          width: 340px;
          height: 510px;
          background: url('/subject/worldCup/wap/choiceBanner.png') no-repeat;
          background-size: 100% 100%;
          position: relative;
          .choiceTop{
            width: 95%;
            margin: 25px auto 0;
            display: flex;
            // overflow-x: auto;
            // overflow-y: hidden;
            // white-space:nowrap;
            box-sizing: border-box;
            div{
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              box-sizing: border-box;
              margin: 0 10px;
              p{
                font-size: 15px;
                font-weight: 600;
                color: #fb8f20;
                margin: 15px auto 10px;
              }
              ul{
                width: 142px;
                height: 163px;
                background: url('/subject/worldCup/countryKK.png') no-repeat;
                background-size: 100% 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                box-sizing: border-box;
                padding-top: 2px;
                li{
                  width: 98%;
                  height: 40px;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  box-sizing: border-box;
                  padding-left: 5px;
                  background-color: #ffffff;
                  position: relative;
                  span{
                    font-size: 13px;
                    color: #d53a1c;
                    font-weight: 550;
                    margin: 0 0px 0 10px;
                  }
                  .select{
                    width: 15px;
                    position: absolute;
                    top: 50%;
                    right: 10px;
                    transform: translate(0,-50%);
                  }
                  .flag{
                    width: 25px;
                  }
                }
                li:nth-child(1){
                  border-top-left-radius: 15px;
                  border-top-right-radius: 15px;
                }
                li:last-child{
                  border-bottom-left-radius: 15px;
                  border-bottom-right-radius: 15px;
                }
                .userChoiceLi{
                  background-color: #fef6e9;
                }
                // .disabledLi{
                //   pointer-events: none;
                // }
              }
            }
          }
          .lrhd{
            width: 60%;
            display: block;
            margin: 8px auto 0;
          }
          .choiceBottom{
            width: 100%;
            .userStatus{
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              .bottomForm{
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                box-sizing: border-box;
                padding: 18px 0 0px;
                input{
                  width: 70%;
                  height: 33px;
                  border-radius: 8px;
                  border: 2px solid#fbab20;
                  text-indent: 20px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 14px;
                  color: #b51e00;
                  font-weight: 550;
                }
                img{
                  width: 155px;
                  cursor: pointer;
                  margin-top: 10px;
                }
                .codeBox{
                  width: 72%;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  margin-top: 10px;
                  input{
                    width: 50%;
                  }
                  span{
                    color: #ef6225;
                    font-size: 13px;
                    font-weight: 550;
                    cursor: pointer;
                  }
                }
              }
              .newTips{
                font-size: 12px;
                transform: scale(0.9);
                margin: 10px auto;
              }
              .bottomLogin{
                display: flex;
                justify-content: center;
                align-items: center;
                span{
                  font-size: 13px;
                  font-weight: 550;
                  color: #ef6225;
                }
                img{
                  width: 155px;
                  cursor: pointer;
                  margin-left: 20px;
                }
              }
            }
            .userStatus1{
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              margin-top: 30px;
              input{
                width: 80%;
                height: 45px;
                border-radius: 10px;
                border: 2px solid#fbab20;
                text-indent: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                color: #b51e00;
                font-weight: 550;
              }
              img{
                cursor: pointer;
                width: 192px;
                margin-top: 30px;
              }
            }
            .userStatus2{
              width: 85%;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
              margin: 30px auto 20px;
              p{
                width: 100%;
                font-size: 15px;
                color: #faad31;
                text-align: justify;
              }
              p:nth-child(2){
                span{
                  font-size: 18px;
                  font-weight: 600;
                  color: #eb7101;
                }
              }
            }
          }
          .endtime{
            width: 100%;
            text-align: center;
            font-size: 14px;
            color: #ffffff;
            position: absolute;
            left: 50%;
            bottom: 10px;
            transform: translate(-50%,0);
          }
        }
      }
      .process{
        width: 292px;
        margin: 25px auto;
      }
      .register{
        width: 326px;
        height: 408px;
        background: url('/subject/worldCup/wap/register.png') no-repeat;
        background-size: cover;
        position: relative;
        img{
          width: 142px;
        }
        .registerImg1{
          position: absolute;
          top: 65%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
        .registerImg2{
          position: absolute;
          top: 78%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
      }
      .LuckyBox{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .luckyTit{
          width: 70%;
          margin-bottom: 15px;
          margin-left: 40px;
        }
        .draw{
          width: 375px;
          height: 590px;
          background: url('/subject/worldCup/wap/drawBackground.png') no-repeat;
          background-size: cover;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          box-sizing: border-box;
          position: relative;
          .drawTit{
            font-size: 15px;
            color: #f46200;
            font-weight: 600;
            text-shadow: 2px 1px 2px rgb(228, 225, 226);
            margin-top: 17px;
            span{
              margin: 0 8px;
            }
            span:nth-child(2){
              color: #ed2823;
            }
          }
          .LuckyWheel{
            margin-top: 25px;
          }
          .zpBottom{
            width: 151px;
            margin-top: -50px;
          }
          .showPrize{
            width: 85%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            position: absolute;
            bottom: 30px;
            .spLeft{
              p{
                font-size: 15px;
                color: #cc4c00;
                margin: 12px 0;
                font-weight: 600;
                letter-spacing: 1px;
              }
            }
            .spRight{
              img{
                width: 142px;
                cursor: pointer;
              }
              p{
                font-size: 15px;
                color: #fb8f20;
                font-weight: 550;
              }
            }
            .spRight2{
              p{
                font-size: 15px;
                margin: 5px auto;
                font-weight: 550;
                color: #b51e00;
              }
              .scrollP{
                overflow-y: auto;
                box-sizing: border-box;
                width: 120px;
                height: 22px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                color: #fc7d29;
                margin-top: 10px;
              }
              .scrollP::-webkit-scrollbar{
                display: inline-block;
                width: 5px !important;
                height: 10px !important;
                background-color: #eee;
                background-clip: content-box;
              }
              .scrollP::-webkit-scrollbar-thumb{
                height: 10px;
                border-radius: 5px;
                background-color: #6d6d6c;
              }
            }
          }
          .handleBox{
            position: absolute;
            bottom: 5px;
            right: 30%;
            // transform: translate(0,-50%);
            display: flex;
            justify-content: center;
            align-items: center;
            img{
              width: 20px;
            }
            span{
              color: #fff;
              margin-left: 8px;
              font-size: 12px;
            }
          }
        }
      }
      .craig{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .craigTit{
          width: 80%;
          margin-bottom: 15px;
        }
        .craigBanner{
          width: 346px;
        }
      }
      .footer{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        padding: 30px 0 50px;
        .footerTxt{
          width: 95%;
        }
        ul{
          width: 75%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 20px;
          li{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            img:nth-child(1){
              width: 110px;
            }
            img:nth-child(2){
              width: 110px;
              height: auto;
              margin-top: 15px;
            }
          }
          li:nth-child(2){
            img:nth-child(1){
              width: 140px;
            }
          }
        }
      }
    }
    .diaShadow{
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      background:rgba(51, 51, 51, 0.5);
    }
    .dialog{
      width: 275px;
      height: 225px;
      background: url('/subject/worldCup/dialogBg2.png') no-repeat;
      background-size: contain;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h3{
        color: #b51e00;
        font-size: 16px;
        margin-bottom: 10px;
      }
      .double{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      p{
        font-size: 16px;
      }
      img{
        width: 150px;
        position: absolute;
        top: -50px;
        left: 50%;
        transform: translate(-50%,0);
      }
      .messagePyellow{
        font-size: 20px;
        font-weight: 600;
        color: #fca10c;
      }
      .messagePred{
        font-size: 20px;
        font-weight: 600;
        color: #f3001e;
      }
      .messagePBlack{
        color: black;
      }
      .messageButton{
        width: 130px;
        height: 35px;
        border: none;
        background-color: #fb7920;
        border-radius: 8px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        margin-top: 12px;
      }
    }
    .codeDialog{
      width: 350px;
      height: 250px;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      background-color: #fef6e9;
      border-radius: 15px;
      z-index: 99;
      border: 2px solid #ffd8a6;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      p{
        span{
          color: #872519;
          font-size: 18px;
          font-weight: 600;
        }
        input{
          width: 150px;
          height: 50px;
          background-color: #ffffff;
          border: none;
          margin-left: 20px;
          text-indent: 20px;
          color: #872519;
        }
      }
      div{
        button{
          width: 120px;
          height: 40px;
          border: 2px solid #fa8221;
          background-color: #ffffff;
          color: #fa8221;
          font-size: 18px;
          border-radius: 8px;
          margin: 0 10px;
        }
        button:nth-child(2){
          background-color: #fa8221;
          color: #ffffff;
        }
      }
    }
    .activityDetailsDialog{
      width: 80%;
      height: 300px;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      background-color: #fff;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 8px 15px;
      overflow-y: scroll;
      img{
        float: right;
        width: 20px;
      }
      h3{
        font-size: 15px;
        color: #f04f54;
        margin: 8px 0;
      }
      p{
        font-size: 14px;
        line-height: 20px;
        span{
          font-weight: 600;
        }
      }
    }
  }
  input::placeholder{
    color:#da8e7f;
    font-size: 14px;
    font-weight: 550;
  }
</style>